<template>
    <div>
        <div v-for="item in 100">
        内容{{item}}
        </div>
    </div>
</template>

<script>
import { Notify } from "vant";

export default {
    data() {
        return {
            queryParams: {
                projectId: null,
                contractId: null,
                relatedId: null,
            },
        };
    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        this.queryParams = {
            projectId: urlParams.get("projectId"),
            contractId: urlParams.get("contractId"),
            relatedId: urlParams.get("relatedId"),
        }
        console.log(this.queryParams)
    },
    methods: {
        getList() {
            Notify({ type: "success", message: "获取数据成功" });
        },
    },
};
</script>

