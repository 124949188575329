import axios from 'axios';

const API_URL = 'https://ts.cciictop.com/prod-api/';
// const API_URL = 'http://localhost:8091/';

export function getDetailList(projectId,contractId,cycleId) {
  return axios.get(`${API_URL}pdf/cycleJlDetailed?projectId=`+projectId+'&contractId='+contractId+'&cycleId='+cycleId);
}

export function mcGetInfo(contractId,cycleId) {
  return axios.get(`${API_URL}pdf/mcGetInfo?contractId=`+contractId+'&id='+cycleId);
}

export function processList(projectId,contractId,cycleApproveId) {
  return axios.get(`${API_URL}pdf/cycleProcess/list?projectId=`+projectId+'&contractId='+contractId+'&cycleApproveId='+cycleApproveId);
}
export function getTuiHuiList(approveId) {
  return axios.get(`${API_URL}pdf/getTuiHuiList/`+approveId);
}

export function examine(data) {
  return axios.post(`${API_URL}pdf/newExamine`,data)
}
export function bindingUser(data) {
  return axios.post(`${API_URL}pdf/bindingUser`,data)
}
