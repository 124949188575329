<template>
    <div>
        <van-collapse v-model="activeNames" accordion>
            <van-collapse-item name="1">
                <template #title>
                    <div style="float: left">
                        <span style="font-size: medium">原始清单</span>
                        <van-icon name="todo-list-o" />
                    </div>
                </template>
                <el-table :data="tableData.ysqdList" class="my-table" stripe max-height="350" >
                    <el-table-column prop="number" label="编号" fixed></el-table-column>
                    <el-table-column prop="columnName" label="名称" fixed ></el-table-column>
                    <el-table-column prop="qriginalQuantity" label="原始量" ></el-table-column>
                    <el-table-column prop="bqNumber" label="本期计量"></el-table-column>
                    <el-table-column prop="bqmNumber" label="本期末计量" width="90" ></el-table-column>
                </el-table>
            </van-collapse-item>
            <van-collapse-item name="2">
                <template #title>
                    <div style="float: left">
                        <span style="font-size: medium">甲供设备</span>
                        <van-icon name="logistics" />
                    </div>
                </template>
                <el-table :data="tableData.jgsbList" class="my-table" stripe max-height="350" >
                    <el-table-column prop="code" label="编号" fixed></el-table-column>
                    <el-table-column prop="name" label="名称" fixed></el-table-column>
                    <el-table-column prop="specs" label="规格"></el-table-column>
                    <el-table-column prop="unit" label="单位"></el-table-column>
                    <el-table-column prop="unitPrice" label="单价"></el-table-column>
                    <el-table-column prop="sqmNum" label="上期末计量" width="90" ></el-table-column>
                    <el-table-column prop="bqNum" label="本期计量"></el-table-column>
                    <el-table-column prop="bqmNum" label="本期末计量" width="90" ></el-table-column>
                </el-table>
            </van-collapse-item>
            <van-collapse-item name="3">
                <template #title>
                    <div style="float: left">
                        <span style="font-size: medium">甲供材料</span>
                        <van-icon name="more-o" />
                    </div>
                </template>
                <el-table :data="tableData.jgclList" class="my-table" stripe max-height="350" >
                    <el-table-column prop="code" label="编号" fixed></el-table-column>
                    <el-table-column prop="name" label="名称" fixed></el-table-column>
                    <el-table-column prop="specs" label="规格"></el-table-column>
                    <el-table-column prop="unit" label="单位"></el-table-column>
                    <el-table-column prop="unitPrice" label="单价"></el-table-column>
                    <el-table-column prop="bqsyNum" label="本期使用"></el-table-column>
                    <el-table-column prop="sqmzcNum" label="上期末暂存" width="90" ></el-table-column>
                    <el-table-column prop="bqlyNum" label="本期领用"></el-table-column>
                    <el-table-column prop="bqzcNum" label="本期暂存"></el-table-column>
                </el-table>
            </van-collapse-item>
            <van-collapse-item name="4">
                <template #title>
                    <div style="float: left">
                        <span style="font-size: medium">甲供物资</span>
                        <van-icon name="info-o" />
                    </div>
                </template>
                <el-table :data="tableData.jgwzList" class="my-table" stripe max-height="350" >
                    <el-table-column prop="name" label="名称" fixed></el-table-column>
                    <el-table-column prop="specs" label="规格"></el-table-column>
                    <el-table-column prop="unit" label="单位"></el-table-column>
                    <el-table-column prop="unitPrice" label="单价"></el-table-column>
                    <el-table-column prop="projectNum" label="使用量"></el-table-column>
                </el-table>
            </van-collapse-item>
            <van-collapse-item name="5">
                <template #title>
                    <div style="float: left">
                        <span style="font-size: medium">代发详情</span>
                        <van-icon name="gold-coin-o" />
                    </div>
                </template>
                <el-table :data="tableData.dfxqList" class="my-table" stripe max-height="350" >
                    <el-table-column prop="dateStr" label="月份"></el-table-column>
                    <el-table-column prop="projectNum" label="数量"></el-table-column>
                    <el-table-column prop="price" label="金额"></el-table-column>
                    <el-table-column prop="payType" label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.payType == '1'">工资</span>
                            <span v-if="scope.row.payType == '2'">机械款</span>
                        </template>
                    </el-table-column>
                </el-table>
            </van-collapse-item>
            <van-collapse-item name="6">
                <template #title>
                    <div style="float: left">
                        <span style="font-size: medium">自备设备</span>
                        <van-icon name="logistics" />
                    </div>
                </template>
                <el-table :data="tableData.zbsbList" class="my-table" stripe max-height="350" >
                    <el-table-column prop="code" label="编号" fixed></el-table-column>
                    <el-table-column prop="name" label="名称" fixed></el-table-column>
                    <el-table-column prop="specs" label="规格"></el-table-column>
                    <el-table-column prop="unit" label="单位"></el-table-column>
                    <el-table-column prop="unitPrice" label="单价"></el-table-column>
                    <el-table-column prop="sqmNum" label="上期末计量" width="90" ></el-table-column>
                    <el-table-column prop="bqNum" label="本期计量"></el-table-column>
                    <el-table-column prop="bqmNum" label="本期末计量" width="90" ></el-table-column>
                </el-table>
            </van-collapse-item>
            <van-collapse-item name="7">
                <template #title>
                    <div style="float: left">
                        <span style="font-size: medium">自备材料</span>
                        <van-icon name="more-o" />
                    </div>
                </template>
                <el-table :data="tableData.zbclList" class="my-table" stripe max-height="350" >
                    <el-table-column prop="code" label="编号" fixed></el-table-column>
                    <el-table-column prop="name" label="名称" fixed></el-table-column>
                    <el-table-column prop="unit" label="单位"></el-table-column>
                    <el-table-column prop="unitPrice" label="单价"></el-table-column>
                    <el-table-column prop="sqmNum" label="本期计量"></el-table-column>
                    <el-table-column prop="bqNum" label="上期末计量" width="90" ></el-table-column>
                    <el-table-column prop="bqmNum" label="本期末计量" width="90" ></el-table-column>
                </el-table>
            </van-collapse-item>
            <van-collapse-item name="8">
                <template #title>
                    <div style="float: left">
                        <span style="font-size: medium">自备物资</span>
                        <van-icon name="info-o" />
                    </div>
                </template>
                <el-table :data="tableData.zbwzList" class="my-table" stripe max-height="350" >
                    <el-table-column prop="name" label="名称" fixed></el-table-column>
                    <el-table-column prop="specs" label="规格"></el-table-column>
                    <el-table-column prop="unit" label="单位"></el-table-column>
                    <el-table-column prop="unitPrice" label="单价"></el-table-column>
                    <el-table-column prop="projectNum" label="使用量"></el-table-column>
                </el-table>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>

<script>
import {
    Collapse,
    CollapseItem,
    Button,
    Dialog,
    DropdownMenu,
    DropdownItem,
    Picker,
    PullRefresh,
    Notify,
} from "vant";
import { Table } from "element-ui";
import { getDetailList, mcGetInfo,  examine,getTuiHuiList } from "@/api/api";

export default {
    components: {
        VanCollapse: Collapse,
        VanCollapseItem: CollapseItem,
        VanButton: Button,
        [Dialog.Component.name]: Dialog.Component,
        VanDropdownMenu: DropdownMenu,
        VanDropdownItem: DropdownItem,
        VanPicker: Picker,
        VanPullRefresh: PullRefresh,
        [Notify.Component.name]: Notify.Component,
        ElTable: Table,
    },
    data() {
        return {
            activeNames: [],
            tableData: {
                ysqdList:[],
                jgsbList:[],
                jgclList:[],
                dfxqList:[],
                zbsbList:[],
                zbclList:[],
                zbwzList:[],
            },
            show: false,
            queryParams: {
                projectId: null,
                contractId: null,
                relatedId: null,
            },
        };
    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        this.queryParams = {
            projectId: urlParams.get("projectId"),
            contractId: urlParams.get("contractId"),
            relatedId: urlParams.get("relatedId"),
        }
        this.getList();
    },
    methods: {
        getList() {
            getDetailList(
                this.queryParams.projectId,
                this.queryParams.contractId,
                this.queryParams.relatedId
            ).then((response) => {
                if (response.data.data != null ) {
                    this.tableData = response.data.data;
                }
            });
        },
    },
};
</script>

<style scoped>
.my-table {
    margin-top: 10px;
    border: none;
    background-color: #f5f5f5;
}
.el-table__body-wrapper {
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
}
.el-table__body-wrapper table {
    width: 100%;
}
.el-table__header th {
    background-color: #f2f2f2;
    font-weight: bold;
}
.el-table__row:hover {
    background-color: #ebebeb;
}
</style>
