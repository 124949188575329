<template>
  <div>
      <nav style="position: sticky; top: 0px" block>
          <span style="font-size: larger" >{{title}}</span>
      </nav>
      <div style="overflow: auto;padding: 10px 20px" ref="nrDiv">
        <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh" >
                <!--  周期数据      -->
                <cycle-view v-if="form.flowType == '1'" ref="cycle"/>
                <!--  变更数据      -->
                <order-token-view v-if="form.flowType == '2'" ref="orderToken" />
        </van-pull-refresh>
      </div>
    <van-dialog v-model="show" title="计量审批" :show-cancel-button="false" close-on-click-overlay @confirm="handleConfirm" >
      <van-form style="margin: 20px 10px">
        <van-field v-model="form.status" label="审批结果" type="radio">
          <template #input>
            <van-radio-group v-model="form.status" direction="horizontal" @change="radioChange" >
              <van-radio name="1">同意</van-radio>
              <van-radio name="2">驳回</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-if="this.form.status === '2'" readonly clickable label="驳回流程" :value="typeLeave" placeholder="选择人员" @click="showPicker = true" />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker title="驳回到" show-toolbar :columns="tuiHuiList" @cancel="showPicker = false"  @confirm="onConfirm" >
            <template #option="option">
              <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                " >
                <div>{{ option.name }}</div>
              </div>
            </template>
          </van-picker>
        </van-popup>
        <van-field v-model="form.remarks" rows="3" autosize label="审批意见" type="textarea" maxlength="50" placeholder="请输入审批意见" show-word-limit />
      </van-form>
    </van-dialog>

    <Button type="info" block style="position: fixed; bottom: 0px" size="large" icon="friends-o" @click="show = true" >审核</Button >
  </div>
</template>

<script>
import CycleView from './cycle/index.vue';
import OrderTokenView from './orderToken/index.vue';
import { Button, Dialog, Picker, PullRefresh, Notify, } from "vant";
import {examine,getTuiHuiList } from "@/api/api";

export default {
  components: {
       Button,
    [Dialog.Component.name]: Dialog.Component,
    VanPicker: Picker,
    VanPullRefresh: PullRefresh,
    [Notify.Component.name]: Notify.Component,
      CycleView, // 周期审批
      OrderTokenView, // 变更审批
  },
  data() {
    return {
      show: false,
      nextCycleProcessId: "",
      showPicker: false,
      typeLeave: null,
      // 流程退回数据集合
      tuiHuiList: [],
      isLoading: false,
      approveId: null,
      form: {
          projectId: null,
          contractId: null,
          relatedId: null,
          flowType: null,
          status: "1",
          remarks: null,
          nextCycleProcessId: null,
          userId: null,
      },
      title:null,
    };
  },
  updated() {
      let nrDiv = this.$refs.nrDiv
      console.log(nrDiv)
      let clientHeight = document.documentElement.clientHeight;
      console.log(clientHeight)
      nrDiv.style.height = clientHeight - 135+'px'
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    this.form = {
        projectId: urlParams.get("projectId"),
        contractId: urlParams.get("contractId"),
        relatedId: urlParams.get("relatedId"),
        flowType: urlParams.get("flowType"),
        status: "1",
        remarks: null,
        nextCycleProcessId: null,
        userId: urlParams.get("userId"),
    }
    this.approveId = urlParams.get("approveId")
    this.setTitle();
    // 调用查询退回流程数据接口
    this.queryTuiHuiList();
    console.log( this.form );
  },
  methods: {
    setTitle(){
        if (this.form.flowType == '1') {
            this.title = '计量审批'
        } else if (this.form.flowType == '2') {
            this.title = '变更审批'
        } else if (this.form.flowType == '3') {
            this.title = '支付报表审批'
        } else if (this.form.flowType == '4') {
            this.title = '设备结算审批'
        } else if (this.form.flowType == '5') {
            this.title = '安全支付审批'
        } else if (this.form.flowType == '6') {
            this.title = '变更变价流程'
        }
    },
    // 表单重置
    reset() {
      this.form = {
        projectId: null,
        contractId: null,
        relatedId: null,
        flowType: null,
        status: "1",
        remarks: null,
        nextCycleProcessId: null,
        userId: null,
      };
    },
    onRefresh() {
      // 触发下拉刷新时的操作
      this.isLoading = true;
      // 周期数据重新加载
      if (this.form.flowType == '1') {
          this.$refs.cycle.getList();
      } else if (this.form.flowType == '2') {
          this.$refs.orderToken.getList();
        }
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },
    // 查询退款流程接口调用
    queryTuiHuiList () {
        getTuiHuiList( this.approveId ).then((response) => {
            this.tuiHuiList = response.data.data;
        });
    },
    // 审核意见同意不同意切换事件
    radioChange() {
      this.typeLeave = null;
      this.form.nextCycleProcessId = null;
      this.form.remarks = null;
    },
    // 驳回是选择框结束后事件
    onConfirm(value, index) {
      console.log(value, index);
      this.form.nextCycleProcessId = this.tuiHuiList[index].id;
      this.typeLeave = this.tuiHuiList[index].name;
      this.showPicker = false;
    },
    // 审核确认按钮事件
    handleConfirm() {
      examine(this.form).then((response) => {
        console.log(response);
        if (response.data.code != 200) {
          Notify({ type: "warning", message: response.data.msg });
        } else {
          Notify({ type: "success", message: "审批成功" });
          this.$router.push("/success");
        }
      });
    },
  },
};
</script>

<style scoped>
.my-table {
  margin-top: 10px;
  border: none;
  background-color: #f5f5f5;
}
.el-table__body-wrapper {
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
}
.el-table__body-wrapper table {
  width: 100%;
}
.el-table__header th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.el-table__row:hover {
  background-color: #ebebeb;
}
</style>
